import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useMediaQuery } from 'react-responsive';

import SquareCompass from '../img/squareandcompass.png';
import Layout from '../components/layout';
import RingingBells from '../img/ringingbells1.jpg';
import Thanksgiving from '../img/thanksgiving.jpg';
import GiftsToGive from '../img/giftstogive.jpg';

const Banner = styled.div`
  background: linear-gradient(90deg, #212121 -10%, #182848 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid white;
  height: 500px;
  text-align: center;

  h2 {
    color: #ffffff;
    margin-bottom: 2rem;
  }
`;

const Container = styled.div`
  text-align: center;
  justify-contents: center;
  display: flex;
  align-items: center;
`;

const SubContainer = styled.div`
  width: 80%;
  flex: 1;
`;

const StyledCarousel = styled(CarouselProvider)`
  box-shadow: none;
  position: relative;
`;

const StyledButton = styled.button`
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding: 0 1rem;
  line-height: 3rem;
  color: #ffffff;
`;

const StyledSquareAndCompass = styled.img`
  height: 150px;
  margin-bottom: 3rem;
`;

const StyledButtonBack = styled(ButtonBack)`
  position: absolute;
  top: 50%;
  left: 5px;
  background-color: #ffffff;
  border: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 1);
`;

const StyledButtonNext = styled(ButtonNext)`
  position: absolute;
  top: 50%;
  right: 5px;
  background-color: #ffffff;
  border: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 1);
`;
export default function Home() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <Layout>
      <Banner>
        <StyledSquareAndCompass src={SquareCompass} alt="squareandcompass" />
        <h2>Brotherhood. Relief. Truth.</h2>
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            navigate('https://massfreemasonry.org/what-is-freemasonry/');
          }}
        >
          Find More in Masonry
        </StyledButton>
      </Banner>
      <Container>
        <SubContainer>
          <StyledCarousel
            touchEnabled
            naturalSlideWidth={100}
            naturalSlideHeight={75}
            totalSlides={3}
            visibleSlides={isMobile ? 1 : 2}
            infinite
          >
            <Slider isPlaying>
              <Slide index={0}>
                <Image src={RingingBells} alt="ringingbells" />
              </Slide>
              <Slide index={1}>
                <Image src={Thanksgiving} alt="thanksgiving" />
              </Slide>
              <Slide index={2}>
                <Image src={GiftsToGive} alt="thanksgiving" />
              </Slide>
            </Slider>
            <StyledButtonBack disabled={false}>{'<'}</StyledButtonBack>
            <StyledButtonNext disabled={false}>{'>'}</StyledButtonNext>
          </StyledCarousel>
        </SubContainer>
      </Container>
    </Layout>
  );
}
